<template>
  <div class="container">
    <Breadcrumb />
    <div>
      <el-button type="success" @click="createFile">创建文件</el-button>
    </div>
    <div>
      <ul>
        <li v-for="(file,index) in files" :key="index">
          <el-button @click="getLink(file.file_id)">{{file.file_name}}</el-button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Breadcrumb from "@/components/Breadcrumb";
import WPSAPI from "./getFile.js";

export default {
  metaInfo() {
    return {
      title: "Wps Test- Ivy-Way Academy"
    };
  },

  components: {
    Breadcrumb
  },

  mixins: [],

  props: [],
  data() {
    return {
      files: []
    };
  },
  computed: {
    ...mapState("user", ["profile"])
  },
  watch: {},

  mounted() {
    this.getFile();
  },

  methods: {
    async createFile(){
      let link =  await WPSAPI.createFile({
        user_id: this.profile.id,
        file_name: "test.docx"
      });
      window.open(link.link_url,"_blank");
    },
    async getFile(){
      const files = await WPSAPI.getFile({
        user_id: this.profile.id
      });
      this.files = files.files;
    },
    async getLink(id){
      let link = await WPSAPI.getLink({
        file_id: id
      });
      window.open(link.link_url,"_blank");
      // console.log(link);
    }
  }
};
</script>

<style scoped></style>
