import ApiService from "@/common/api.service";
export default {
  createFile(data) {
    return ApiService.post("wps/files/create-link", data);
  },
  getFile(data) {
    return ApiService.query("wps/files/list", data);
  },
  getLink(data) {
    return ApiService.query("wps/files/get-link", data);
  }
};
